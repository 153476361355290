import React, { useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Seo from 'src/components/Seo'
import PageLayout from 'src/components/PageLayout'
import Box from '@local/shared/components/System/Box'
import { Typography } from '@local/shared/components/System'
import { MainCta, MainCta2 } from 'src/components/Button'
import OutboundLink from 'src/components/OutboundLink'
import whyDietsFailImg from 'src/images/why-diets-fail-v2.svg'
import Img from 'gatsby-image'
import eatDontEatImg from 'src/images/eat-dont-eat.gif'
import LeadCapture from 'src/components/LeadCapture/LeadCapture'
import InputGroup from 'src/components/InputGroup'
import Countdown from 'src/components/Countdown'
import Reviews from 'src/components/Reviews'
import { useQueryParam } from 'gatsby-query-params'
import Section from 'src/components/Section'
import { Hem, Em, Uem } from 'src/components/Em'
import SavvasProfilePic from 'src/components/SavvasProfilePic'
import styled from 'styled-components'

const SectionTitle = ({ children }) => (
  <Typography variant={'h2'} mb={'0.5em'} textAlign={'left'}>
    {children}
  </Typography>
)

const HeaderTitle = ({ children }) => (
  <Typography
    variant={'h4'}
    mb={'1em'}
    css={{ textTransform: 'uppercase', fontWeight: 'normal' }}
    textAlign={'center'}
  >
    {children}
  </Typography>
)

const QuoteBox = ({ children }) => (
  <Typography
    variant={'h4'}
    as={'p'}
    py={'1em'}
    px={'0.5em'}
    my={'1em'}
    mt={'1em'}
    mb={'2em'}
    textAlign={'center'}
    bgcolor={'blue.0'}
    width={1}
    mx={'auto'}
    borderRadius={10}
    color={'white'}
  >
    {children}
  </Typography>
)

const CountdownFreeAppointmentRemaining = () => (
  <Countdown
    text={
      <>
        Only <strong>3 free consultations</strong> remaining over the next 7
        days.
      </>
    }
    len={10}
    countdown={3}
  />
)

let refCounter = 0
const Reference = ({ href }) => {
  refCounter++
  return (
    <OutboundLink
      href={href}
      target={'_blank'}
      style={{ verticalAlign: 'super', fontSize: '0.5em' }}
    >
      [{refCounter}]
    </OutboundLink>
  )
}

const GOOGLE_ADS_AD_GROUP_ID_MAP_HEADLINE = {
  // Lose weight fast
  // https://ads.google.com/aw/ads?campaignId=9897784215&adGroupId=103435828714&ocid=407477232&authuser=0&__u=7631853328&__c=7887929968
  '103435828714': {
    headline: (
      <>
        If you want to <Em>lose weight as fast as humanly possible</Em>, then
        you don’t need another diet.
        <br />
        <Em>All you need is the right information</Em>.
        <br />
        <br />
        Here it is.
        <br />
        <br />
        ...or you can continue to do the same thing and{' '}
        <Em>hope the weight magically falls off</Em>.
      </>
    ),
    ctaSubtitle: 'Learn the fastest way to lose weight!',
  },
  // Lose Belly Fat
  // https://ads.google.com/aw/ads?campaignId=9897784215&adGroupId=103435828994&ocid=407477232&authuser=0&__u=7631853328&__c=7887929968
  '103435828994': {
    headline: (
      <>
        If you want to <Em>lose belly fat</Em>, then you don’t need another
        diet.
        <br />
        <Em>All you need is the right information</Em>.
        <br />
        <br />
        Here it is.
        <br />
        <br />
        ...or you can continue to do the same thing and{' '}
        <Em>
          hope your belly magically flattens the next time you look in the
          mirror
        </Em>
        .
      </>
    ),
    ctaSubtitle: 'Learn the best way to lose belly fat!',
  },
  // Best Way To Lose Weight
  // https://ads.google.com/aw/ads?campaignId=9897784215&adGroupId=103435829034&ocid=407477232&authuser=0&__u=7631853328&__c=7887929968
  '103435829034': {
    headline: (
      <Em>
        If you've tried every diet, it might be obvious by now that there is no
        best diet.
        <br />
        <br />
        Yet, they all claim to be the best... How can that be?
        <br />
        <br />
        The truth is, the best diet for you has nothing to do with the food you
        eat.
        <br />
        <br />
        Here is the best (and last) “diet” you'll ever need to try...
      </Em>
    ),
    ctaSubtitle: 'Learn the best way to lose weight!',
  },
  // Help To Lose Weight
  // https://ads.google.com/aw/ads?campaignId=9897784215&adGroupId=103435828954&ocid=407477232&euid=136297872&__u=7631853328&uscid=407477232&__c=7887929968&authuser=0&subid=GB-en-xs-ip-gmb-left-nav
  '103435828954': {
    headline: (
      <>
        This could be <Em>your last chance</Em> to get the body you’ve always
        wanted.
        <br />
        <br />
        We will make sure it’s the <Em>last time you try</Em>.
      </>
    ),
    ctaSubtitle: `We'll help you lose weight`,
  },
  default: {
    headline: (
      <>
        If you think that you’re <Em>magically going to lose weight</Em> after
        doing the <Em>same thing</Em> for the last <Em>31 years</Em>
        ...
        <br />
        <Em>then carry on</Em>!
      </>
    ),
    ctaSubtitle: 'Learn why you can’t lose weight!',
  },
}

const Headline = styled(Typography)`
  animation: fadeInFromNone 2s ease-out;

  @keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }
`

const LandingPageV2 = ({ pageLayoutType, location }) => {
  let buttonIndex = 0
  let sectionIndex = 0
  const formFieldRef = useRef(null)
  const gAdsAdGroupId = useQueryParam('ga_adgroupid')
  const [googleAdsConfig, setGoogleAdsConfig] = useState(null)
  useEffect(() => {
    console.log('gAdsAdGroupId', gAdsAdGroupId)
    const valToSet =
      GOOGLE_ADS_AD_GROUP_ID_MAP_HEADLINE[gAdsAdGroupId] ||
      GOOGLE_ADS_AD_GROUP_ID_MAP_HEADLINE['default']
    setGoogleAdsConfig(valToSet)
  }, [gAdsAdGroupId])

  console.log('gAdsAdGroupId', gAdsAdGroupId)
  // const googleAdsConfig =
  //   gAdsAdGroupId !== undefined ||
  //   GOOGLE_ADS_AD_GROUP_ID_MAP_HEADLINE[gAdsAdGroupId] ||
  //   GOOGLE_ADS_AD_GROUP_ID_MAP_HEADLINE['default']

  const data = useStaticQuery(graphql`
    query {
      sadSalad: file(relativePath: { eq: "sad-salad.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      whatVsWhen: file(relativePath: { eq: "what-vs-when.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      savvasProfile: file(relativePath: { eq: "savvas-profile.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <PageLayout type={pageLayoutType}>
      <Seo
        title="WildLife Weight Loss Coaching"
        description={
          'Lose weight, get fit and become a superhuman using tried-and-tested techniques used by our ancestors.'
        }
        shortTeaser={
          'Lose weight, get fit and become a superhuman using tried-and-tested techniques used by our ancestors.'
        }
        locationPathname={location.pathname}
      />
      <Box bgcolor={'blue.0'}>
        <Box py={'2em'} px={'2em'} maxWidth={600} mx={'auto'}>
          <Headline textAlign={'center'} variant={'h1'} color={'white'}>
            {googleAdsConfig && googleAdsConfig['headline']}
          </Headline>
          <MainCta
            mainContent={'Book Free 30-Minute Phone Consultation'}
            subtitleText={googleAdsConfig && googleAdsConfig['ctaSubtitle']}
            trackingName={buttonIndex++}
            mt={'2em'}
            onClick={() => formFieldRef.current.focus()}
          />
        </Box>
      </Box>
      <Section trackingName={sectionIndex++}>
        <HeaderTitle>Dear Dieter</HeaderTitle>
        <SectionTitle>
          Have you been on a diet for the last 31 years?
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          <Hem>The average woman spends 31 years of her life dieting</Hem>.
          That's literally half your adult life. By “dieting”, that means
          changing the way you would naturally eat in order to lose weight.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          If you're aged between 45 and 64, then you're likely the most
          committed. By the age of 60, you might be on your 61st diet by now!{' '}
          <Hem>But each diet lasts just five and a half weeks!</Hem>
          <Reference
            href={
              'https://www.dailymail.co.uk/health/article-430913/Average-woman-spends-31-years-diet-researchers-say.html'
            }
          />
        </Typography>
      </Section>
      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          Dieting is <Uem>boring</Uem> and <Uem>depressing</Uem>.
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Normally, this means{' '}
          <Hem>saying no to all the foods you really want</Hem>. Bread. Steak.
          Butter. Cheese. Bacon.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Now you have to <Hem>eat boring stuff</Hem>. Salads. Turkey breast.
          Fake sugar.
        </Typography>
        <Box
          my={'2em'}
          as={Img}
          borderRadius={10}
          fluid={data.sadSalad.childImageSharp.fluid}
        />
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Maybe you’re even counting calories.{' '}
          <Hem>Deliberating over every gram of food.</Hem> Maybe you’re tracking
          every step. Counting every calorie. Turning life into an equation.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          But <Hem>you’re eating so little</Hem> that you just walk around{' '}
          <Hem>depressed, cranky and starving all day</Hem>.{' '}
          <Uem>We know. It sucks!</Uem>
        </Typography>
      </Section>

      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          ...and it clearly <Uem>doesn't work</Uem>.
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          But if dieting worked, why would you need to be on a diet half of your
          adult life?
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Clearly the weight isn’t staying off. Sure you lost 1 kg two months
          ago cutting out bread. But <Hem>now you weigh more than before</Hem>.
          Is that success?
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          This is happening to everyone! It means only one thing:
        </Typography>
        <QuoteBox>
          Dieting <Em>doesn't work</Em>!
        </QuoteBox>
      </Section>

      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          It gets worse: <Uem>Dieting damages the body</Uem>!
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          After reducing your calories for a certain amount of time, the body
          adapts. But not in a good way.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          While you lose weight at the start,{' '}
          <Hem>the body thinks you are starving it</Hem>.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          It makes you <Hem>hungrier</Hem>.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          It makes you get <Hem>full slower</Hem>.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          It makes you <Hem>crave high-calorie foods</Hem>.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          It makes your <Hem>metabolism work slower</Hem>.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          It makes it <Hem>harder and harder for you to lose weight</Hem>.
          <Reference
            href={'https://www.nejm.org/doi/full/10.1056/NEJMoa1105816'}
          />
        </Typography>
        <QuoteBox>
          <Em>As you get older</Em>, after years of failed diets,{' '}
          <Em>the body makes it nearly impossible to lose weight</Em> .
        </QuoteBox>
      </Section>

      <Section trackingName={sectionIndex++}>
        <SectionTitle>Why is my body doing this to me?</SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          The body tries its hardest to keep everything the same. It’s called{' '}
          <Hem>homeostasis</Hem>.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          As you reduce your calories, <Hem>your body gets worried</Hem>. It
          doesn’t know when you’re going to get your next proper meal.{' '}
          <Hem>It holds onto the fat you already have.</Hem>
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          And then it adapts to make sure that it holds onto it better next
          time. <Hem>It slows your metabolism down!</Hem>
        </Typography>
        <Box
          as={'img'}
          src={whyDietsFailImg}
          width={1}
          mt={'2em'}
          borderRadius={10}
        />
      </Section>

      <Section trackingName={sectionIndex++}>
        <SectionTitle>You're probably broken by now!</SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          After years of dieting, your body is broken. Your hunger is high.{' '}
          <Hem>Every diet you try now is going to fail.</Hem>
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          You’re never going to be able to stick to a diet. All diets have
          something in common: They only work if you stick to them.
        </Typography>
        <QuoteBox>
          But for anything to work now, <Em>you need to fix your metabolism</Em>
          !
        </QuoteBox>
      </Section>
      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          If you're over 40, this might be <Uem>your last chance</Uem>.
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          You need to act quickly. The longer you leave it, the harder it’s
          going to get. You must fix your metabolism as quickly as possible.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          If you’re obese,{' '}
          <Hem>you’re 80 times more likely to develop Type 2 Diabetes</Hem>{' '}
          <Reference
            href={'https://www.diabetes.co.uk/diabetes-and-obesity.html'}
          />
          .
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          If you develop Type 2 Diabetes,{' '}
          <Hem>you’re twice as likely to die young</Hem>{' '}
          <Reference
            href={
              'https://www.diabetes.org.uk/about_us/news/premature-deaths-diabetes'
            }
          />{' '}
          .
        </Typography>
        <QuoteBox>
          For your long-term health you need to <Em>fix your metabolism now</Em>
          !
        </QuoteBox>
      </Section>

      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          A <Uem>new way</Uem> to fix your metabolism.
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          At WildLife, <Hem>we know the secrets to fix a broken body</Hem>. It’s
          a miracle really. It’s a method so powerful that it can even reverse
          Type 2 Diabetes{' '}
          <Reference
            href={
              'https://www.medicalnewstoday.com/articles/323316#Intermittent-fastings-effects-on-diabetes'
            }
          />{' '}
          .
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          It might be a method new to you, but it has been around for over
          10,000 years. It’s so powerful that{' '}
          <Hem>it has stood the test of time</Hem>.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          People spend so much time focusing on what to eat.{' '}
          <Hem>
            But what about <Uem>when</Uem> to eat?
          </Hem>{' '}
          It’s just as important!
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          That's all fasting is.
        </Typography>
        <QuoteBox>
          “<Em>Fasting</Em> is just a realisation that{' '}
          <Em>when to eat is just as important</Em> as what to eat.”
        </QuoteBox>
        <Box
          my={'2em'}
          as={Img}
          borderRadius={10}
          fluid={data.whatVsWhen.childImageSharp.fluid}
        />
      </Section>
      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          Fasting teaches you how to <Uem>turn stored fat into fuel</Uem>!
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Fasting resets your metabolism. It resets how your body calls for
          food. It resets how the mind connects with food.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Fasting turns on systems in your body that have never been activated
          before. <Hem>It tells your body to turn fat into fuel.</Hem>
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          <Hem>
            It can reverse 30+ years of unhealthy yo-yo dieting in just a few
            months.
          </Hem>{' '}
          Ever heard of the keto diet? Fasting is keto x10000!
        </Typography>
      </Section>
      <Section trackingName={sectionIndex++}>
        <SectionTitle>Fasting doesn't require willpower.</SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          In today's world, there's food everywhere. In fact,{' '}
          <Hem>there's too much food</Hem>!
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          It's{' '}
          <Hem>
            hard to constantly make decisions about what to eat and what not to
            eat
          </Hem>
          . This is called decision fatigue. This is why Steve Jobs always wore
          the same outfit.
        </Typography>
        <Box
          width={1}
          mb={'2em'}
          as={'img'}
          borderRadius={10}
          src={eatDontEatImg}
        />
        <Typography variant={'subtitle'} mb={'0.5em'}>
          <Hem>There's always an excuse for chocolates</Hem>, biscuits and cake.
          And there's always the same guilt that comes afterwards.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          But when you're fasting, it's as simple as "no, not right now".{' '}
          <Hem>It makes life so much simpler.</Hem>
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          This makes fasting the greatest cure for snacking ever seen. It
          teaches you that you don't always need to be fed.
        </Typography>
        <QuoteBox>
          “Fasting is the <Em>greatest cure for snacking</Em> ever seen!”
        </QuoteBox>
      </Section>

      <Section trackingName={sectionIndex++}>
        <SectionTitle>Fasting is free!</SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Fasting has been a natural part of every major religion in the world.
          It has been around for generations.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          But <Hem>fasting is not convenient for big corporations</Hem>. How do
          you sell fasting? It’s easier to sell health foods and breakfast
          cereals, gluten-free "this" and low-fat "that".
        </Typography>
        <QuoteBox>
          We’ve been made to believe that fasting is bad for you! But{' '}
          <Em>this is simply wrong</Em>!
        </QuoteBox>
      </Section>
      <Section trackingName={sectionIndex++}>
        <SectionTitle>Fasting can fix you!</SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          You could continue in the same way. Waiting for the next fad diet to
          fix you. Waiting another year,{' '}
          <Hem>hoping the weight will magically fall off</Hem>. Wondering if
          it’s too much bread. If it’s too many biscuits. “Is fruit good or
          bad?” “Maybe I need to go vegan?”
        </Typography>
        <QuoteBox>
          “The <Em>likely scenario</Em> is: Everything is going to{' '}
          <Em>stay the same</Em> unless you <Em>fix what is broken</Em>!”
        </QuoteBox>
      </Section>
      <Section trackingName={sectionIndex++}>
        <SectionTitle>“If I don’t eat for an hour, I get cranky!”</SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Look. We know that mention of fasting makes people uncomfortable.
          <Hem>Sometimes you need a bit of help.</Hem>
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          At WildLife, <Hem>we know all the tricks of the trade</Hem>. We’re
          seasoned professionals. We know how to <Hem>never feel hungry</Hem>.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          We know how to feel amazing, even after 11 days straight (not that
          you’ll have to go that long - we just like a challenge!).
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          We know how to <Hem>balance</Hem> fasting while{' '}
          <Hem>still eating all the foods you enjoy</Hem> and{' '}
          <Hem>not sacrificing your social life</Hem> like so many diets.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          We know how to change your eating plan so you can{' '}
          <Hem>reach your goals as quickly as possible</Hem>.
        </Typography>
        <QuoteBox>
          Most importantly:{' '}
          <Em>We know how to make sure you fix your metabolism</Em> and{' '}
          <Em>keep the weight off</Em>! Forever!
        </QuoteBox>
      </Section>
      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          When you're not fasting, you can be feasting.
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Of course, you should still be eating consciously. Avoiding stuff that
          isn’t real food. We all know what’s not good for us.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          But when you’re living a lifestyle that’s built around fasting, you
          can be a little bit more liberal.{' '}
          <Hem>You don’t have to say no to everything.</Hem> An extra spoon of
          icecream is fine! <Hem>You’ve deserved it.</Hem>
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Relax and enjoy your time to feast. But a word of warning: Your eyes
          will be bigger than your stomach.
        </Typography>
        <QuoteBox>
          “Fasting can be though of a completely natural gastric bypass.{' '}
          <Em>It shrinks your appetite so you won't even want to eat.</Em>
          You don't need willpower.”
        </QuoteBox>
      </Section>
      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          Fasting is the most effective way to <Uem>lose that belly fat</Uem>{' '}
          that just won’t go.
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          <Hem>Having belly fat is dangerous</Hem>. And it’s not just the fat
          you can see. There’s just as much below the surface, suffocating your
          organs.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          The facts are simple.{' '}
          <Hem>Your waist size is just as important as your total weight.</Hem>{' '}
          If have a large waist, you have up to a 50% higher chance of dying
          young{' '}
          <Reference
            href={
              'https://www.webmd.com/diet/news/20081112/belly-fat-doubles-death-risk#2'
            }
          />
          .
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          And belly fat is usually the last thing to go. The more you have, the
          harder it is to lose.{' '}
          <Hem>
            This is not because you're eating too much. It's because of hormonal
            imbalances{' '}
            <Reference
              href={'https://www.dietdoctor.com/my-single-best-weight-loss-tip'}
            />{' '}
            .
          </Hem>
        </Typography>
        <QuoteBox>
          One of the most powerful effects of fasting:{' '}
          <Em>
            Fixing your hormones to finally get rid of stubborn belly fat!
          </Em>
        </QuoteBox>
      </Section>
      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          Fix your body. <Uem>Fix your life!</Uem>
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Everyone has their own reasons for losing weight. But we’re sure we
          don’t need to convince you that{' '}
          <Hem>when you look good, you feel good</Hem>. As you drop-down in
          dress-size, the compliments fly in.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          When you lose weight, everyone around you loses weight. Your family.
          Your friends. It’s an amazing feeling. You’ll have more energy. More
          for your job. More for your children. More for your partner.{' '}
          <Hem>More for yourself!</Hem>
        </Typography>
        <QuoteBox>
          You can have it too. What you’ve always been dreaming about.{' '}
          <Em>Make the magic come true</Em>.
        </QuoteBox>
      </Section>
      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          We’ll teach you how to make fasting{' '}
          <Uem>work for you safely and happily</Uem>!
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          Fasting is a lost tradition. And it’s so effective. At WildLife, we
          only trust methods that have stood the test of time.{' '}
          <Hem>No fads.</Hem>
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          We’ll give you a{' '}
          <Hem>
            <Uem>completely free</Uem> 30-minute phone consultation
          </Hem>
          .
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          We’ll evaluate your current weight loss methods.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          <Hem>We’ll tell you everything you want to know.</Hem>
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          We’ll give you all the resources to get started by yourself.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          We’ll help you just give it a go.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          The magic you’re waiting for might finally happen.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          You just have to make the first move.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          <Hem>Do it for yourself!</Hem>
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          You can have it too. What you’ve always been dreaming about. Make the
          magic come true.
        </Typography>
        <QuoteBox>
          Book a <Em>completely free 30-minute phone consultation</Em> and we'll
          tell you <Em>literally everything you want to know</Em> about fasting
          and other complimentary weight-loss methods.
        </QuoteBox>
        <MainCta
          mainContent={'Book Free 30-Minute Phone Consultation'}
          subtitleText={googleAdsConfig && googleAdsConfig['ctaSubtitle']}
          mt={'2em'}
          mx={'auto'}
          trackingName={buttonIndex++}
          onClick={() => formFieldRef.current.focus()}
        />
        <CountdownFreeAppointmentRemaining />
      </Section>

      <Section trackingName={sectionIndex++}>
        <SectionTitle>From here, you have three options.</SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          <strong>Option #1</strong> <Hem>Do nothing</Hem> and continue to
          search, hope and dream that something magical is going to happen while
          you <Hem>continue failing at the next diet</Hem> that comes along.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          <strong>Option #2</strong> If you're convinced to give fasting a go,
          <Hem>try it alone</Hem>. Warning:{' '}
          <Hem>Without the right information, it's not so simple</Hem>. But with
          the right information, it's so simple and effective.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          <strong>Option #3</strong> Just book a completely free consultation
          with us.{' '}
          <Hem>It's with absolutely no obligation to buy or do anything.</Hem>{' '}
          We'll tell you everything you want to know. If you do want to go
          alone, <Hem>we'll give you a head-start</Hem> you'll never read in any
          book. We've already made all the mistakes ourselves. We know exactly
          how fasting feels and how it works in the body and the mind.
        </Typography>
        <QuoteBox>
          <Em>We can show you how</Em> to get the <Em>body</Em> and the{' '}
          <Em>life</Em> you’ve always wanted. But <Em>it's up to you</Em> to
          make your dreams come true!
        </QuoteBox>
        <MainCta
          mainContent={'Book Free 30-Minute Phone Consultation'}
          subtitleText={googleAdsConfig && googleAdsConfig['ctaSubtitle']}
          mt={'2em'}
          mx={'auto'}
          trackingName={buttonIndex++}
          onClick={() => formFieldRef.current.focus()}
        />
        <CountdownFreeAppointmentRemaining />
      </Section>
      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          What will I learn on my free phone consultation?
        </SectionTitle>
        <ol>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            Get completely personalised{' '}
            <Hem>analysis of your current weight loss strategies</Hem>.
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            A <Hem>free assessment</Hem> to see if you're already likely to have
            a <Hem>slow metabolism</Hem>.
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            How to <Hem>ease into fasting</Hem> the right way while{' '}
            <Hem>avoiding any hunger or discomfort</Hem>.
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            How to <Hem>extend a fast easily by drinking the right stuff</Hem>{' '}
            (and not ruin your fast by <Hem>drinking the wrong stuff</Hem>!)
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            <Hem>What to eat when you're not fasting</Hem> to make your fasts
            even more effective.
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            Exactly <Hem>how to stay well-nourished</Hem> throughout a fast!
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            How you can actually{' '}
            <Hem>perform better physically and mentally</Hem> during a fast!
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            How to quickly transition to a fasted state in less than 24 hours to
            completely <Hem>avoid any muscle wastage</Hem> (this is extremely
            important!)
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            Our top 5 tips for <Hem>maximising sleep while fasting</Hem> (this
            can be tricky if you don't prepare correctly but is{' '}
            <Hem>easy when it's done right</Hem>!)
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            We'll tell you the{' '}
            <Hem>one and only kind of exercise you should be doing</Hem> in
            order to maximise your weight loss alongside fasting. Everything
            else will be a waste of time. Note: It's not running. And no, you
            don't have to go to the gym!
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            We'll even give you a <Hem>complete 5-phase weight loss plan</Hem>{' '}
            for you to get started right away! <Hem>(Normally worth £100)</Hem>
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'} as={'li'}>
            Learn about a bunch of other{' '}
            <Hem>
              complimentary ways to boost your metabolism and enhance your fast
            </Hem>{' '}
            (these all make things work so much faster).
          </Typography>
        </ol>
        <MainCta
          mainContent={'Book Free 30-Minute Phone Consultation'}
          subtitleText={googleAdsConfig && googleAdsConfig['ctaSubtitle']}
          mt={'2em'}
          mx={'auto'}
          trackingName={buttonIndex++}
          onClick={() => formFieldRef.current.focus()}
        />
        <CountdownFreeAppointmentRemaining />
      </Section>
      <Reviews borderTop={0} />
      <Section trackingName={sectionIndex++}>
        <SectionTitle>
          What are the <Uem>other benefits of fasting</Uem>?
        </SectionTitle>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          The benefits of fasting don't stop with weight loss. Getting slimmer
          is just an expression of your body becoming healthier.
        </Typography>
        <Typography variant={'subtitle'} mb={'0.5em'}>
          People typically see or experience:
        </Typography>
        <ul>
          <Typography variant={'subtitle'} as={'li'} mb={'0.5em'}>
            The lightest stomach you're ever going to feel.{' '}
            <Hem>No bloating at all!</Hem>
          </Typography>
          <Typography variant={'subtitle'} as={'li'} mb={'0.5em'}>
            <Hem>Continuous energy levels</Hem> throughout the day. (No sugar
            spikes or crashes!)
          </Typography>
          <Typography variant={'subtitle'} as={'li'} mb={'0.5em'}>
            Massively <Hem>improved attention span</Hem> and{' '}
            <Hem>elimination of brain fog</Hem>.
          </Typography>
          <Typography variant={'subtitle'} as={'li'} mb={'0.5em'}>
            <Hem>Relaxation of skin rashes and allergies</Hem> (there's nothing
            to aggravate them from your diet!)
          </Typography>
          <Typography variant={'subtitle'} as={'li'} mb={'0.5em'}>
            Incredible dental hygiene!
          </Typography>
          <Typography variant={'subtitle'} as={'li'} mb={'0.5em'}>
            <Hem>Amazing efficiency gains</Hem> by not having to think about,
            buy, prepare, cook, eat, pick, digest or clean-up any food!
          </Typography>
          <Typography variant={'subtitle'} as={'li'} mb={'0.5em'}>
            <Hem>Unreal cost savings!</Hem> You don't need to buy expensive
            health foods or equipment. You just don't buy anything at all!
          </Typography>
        </ul>
        <MainCta
          mainContent={'Book Free 30-Minute Phone Consultation'}
          subtitleText={googleAdsConfig && googleAdsConfig['ctaSubtitle']}
          mt={'2em'}
          mx={'auto'}
          trackingName={buttonIndex++}
          onClick={() => formFieldRef.current.focus()}
        />
        <CountdownFreeAppointmentRemaining />
      </Section>
      <LeadCapture
        title={'Book your free 30-minute phone consultation!'}
        subtitle={`Finally: Lose weight after years of trying!`}
        countdown={<CountdownFreeAppointmentRemaining />}
        bullets={
          <>
            <ul>
              <Typography variant={'body1'} mb={'0.5em'} as={'li'}>
                Learn exactly <Hem>why you can't lose weight</Hem>!
              </Typography>
              <Typography variant={'body1'} mb={'0.5em'} as={'li'}>
                Learn simple strategies to <Hem>reset your metabolism</Hem>.
              </Typography>
              <Typography variant={'body1'} mb={'0.5em'} as={'li'}>
                <Hem>Speak to an professional</Hem> about how fasting can work
                for me.
              </Typography>
              <Typography variant={'body1'} mb={'0.5em'} as={'li'}>
                Learn how to{' '}
                <Hem>finally get the body and the life you want</Hem> after
                years of trying!
              </Typography>
              <Typography variant={'body1'} mb={'0.5em'} as={'li'}>
                Get a free <Hem>complete 5-phase weight loss plan</Hem> for you
                to get started right away! <Hem>(Normally worth £100)</Hem>
              </Typography>
            </ul>
            <Typography variant={'body1'} mb={'0.5em'} ml={'1em'}>
              ...and so much more!
            </Typography>
          </>
        }
        rhs={
          <Box width={{ md: '100%' }}>
            <SavvasProfilePic />
            <Typography
              variant={'h6'}
              as={'p'}
              textAlign={'center'}
              mb={'0.5em'}
            >
              Coach Savvas Nicholas
            </Typography>
            <Typography variant={'subnote'} as={'p'} textAlign={'center'}>
              Weight Loss Expert
            </Typography>
          </Box>
        }
        formAction={'/thank-you/free-consultation'}
        formName={'lead-capture-free-consultation'}
        formFields={
          <>
            <InputGroup
              name={'full-name'}
              id={'full-name'}
              labelText={'Full Name'}
              placeholder={'Full Name'}
              type={'text'}
              ref={formFieldRef}
            />
            <InputGroup
              name={'email'}
              id={'email'}
              labelText={'Email'}
              placeholder={'Email'}
              type={'email'}
            />
            <MainCta
              width={1}
              trackingName={'submit'}
              type={'submit'}
              mainContent={'Book Free 30-Minute Phone Consultation'}
              subtitleText={googleAdsConfig && googleAdsConfig['ctaSubtitle']}
            />
            <Typography variant={'subnote'} textAlign={'center'} mt={'0.5em'}>
              We will email to arrange a time that suits you
            </Typography>
          </>
        }
      />
    </PageLayout>
  )
}

export default LandingPageV2
